import {mapMutations} from "vuex";


export default {
  name: "articles",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapMutations({}),
    goToArticles(){

    }
  }
}
