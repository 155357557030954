export const validation = {
    components: {},
    name: "validation",
    data() {
        return {}
    },
    props: {
        validateEmail: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        validation(ref, payload, minSymbols) {
            let location = window.location.pathname.slice(1, 3)
            console.log(location, 'window')
            let error = ref
            let name = payload.length
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

            switch (true) {
                case name === 0:
                    if (error) {
                        error.innerHTML = ''
                        if (location === 'uk') {
                            error.append('Заповніть поля');
                        } else {
                            error.append('Заполните поля');
                        }


                    }
                    break;

                case this.validateEmail :
                    // error.innerHTML = ''
                    // error.append(`enter valid E-mail`)
                    break;

                case !this.validateEmail && name < minSymbols && name > 0:
                    if (error) {
                        error.innerHTML = ''
                        if (location === 'uk') {
                            error.append('Минимальное кількість символів - 19');
                        } else {
                            error.append('Минимальное количество символов - 19');
                        }
                    }
                    break;
            }
        },
    }
}

