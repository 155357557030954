import {mapGetters, mapMutations} from "vuex";
import Vue from "vue";

//sections
export default {
    name: "color-palate",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            image: '',
            canvas: '',
            context: '',
            hex: '',
            windowWidth: window.innerWidth
        }
    },
    created() {

    },
    mounted() {
        var canvas = document.getElementById("canvas");

        function getElementPosition(obj) {
            var curleft = 0, curtop = 0;
            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                } while (obj = obj.offsetParent);
                return {x: curleft, y: curtop};
            }
            return undefined;
        }

        function getEventLocation(element, event) {
            var pos = getElementPosition(element);

            return {
                x: (event.pageX - pos.x),
                y: (event.pageY - pos.y)
            };
        }

        function rgbToHex(r, g, b) {
            if (r > 255 || g > 255 || b > 255)
                throw "Invalid color component";
            return ((r << 16) | (g << 8) | b).toString(16);
        }

        function drawImageFromWebUrl(sourceurl) {
            var img = new Image();

            img.addEventListener("load", function () {

                canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

            });

            img.setAttribute("src", sourceurl);
        }

        drawImageFromWebUrl(this.item.imageBase64);
        canvas.addEventListener("click", function (e) {
            var eventLocation = getEventLocation(this, e);

            var context = this.getContext('2d');
            var pixelData = context.getImageData(eventLocation.x, eventLocation.y, 1, 1).data;

            this.hex = "#" + ("000000" + rgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);
            if (this.hex === '#ffffff') {
                return
            }
            // let decoColor = this.$refs["decoColor"].style
            // decoColor.fill = this.ref

            let decoColor = document.getElementById("decoColor");
            let duoColor = document.getElementById("duoColor");
            decoColor.style.fill = this.hex
            duoColor.style.fill = this.hex

        }, false);
    },
    methods: {}
}
