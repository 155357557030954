import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
  name: "calculator-message",
  components: {},

  data() {
    return {}
  },


  created() {

  },
  mounted() {

  },
  watch: {},
  computed: {
    ...mapGetters({
      isShowCalculatorMessage: 'constructors/messageContent'
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  }
}
