import {mapMutations} from "vuex";

export default {
    name: "order-product",
    props: {
        item: {
            type: Object,
            default: () => {
            },
        },
    },
    components: {},
    data() {
        return {
            isPlayVideo: false,
        }
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({
            isShowTrialBatch: 'landings/ORDER_TRIAL_BATCH',
        }),
        sendOrder() {
            this.isShowTrialBatch(true)
        },

    }
}
