import {mapGetters, mapMutations} from "vuex";
import Vue from "vue";

//sections
export default {
  name: "color",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {
  },
  methods: {}
}
