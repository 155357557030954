import {mapMutations, mapActions, mapGetters} from "vuex";
import {validation} from "@/mixins/validation.js";
//sections


export default {
    name: "fast-order",
    components: {},
    props: {
        ...validation.props,
        title: {
            type: String,
            default: ''
        },
        errorName: {
            type: Boolean,
            default: false
        },
        errorSurname: {
            type: Boolean,
            default: false
        },
        errorPhone: {
            type: Boolean,
            default: false
        },
        errorEmail: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            name: '',
            secondName: '',
            phone: '',
            email: '',
            nameError: false,
            secondNameError: false,
            emailError: false,
            phoneError: false

        }
    },
    mixins: ['validation'],

    created() {

    },
    mounted() {

    },
    watch: {
        name() {
            if (this.name.length > 0) {
                this.$refs.loginName.textContent = ''
                this.nameError = false
            }
        },
        secondName() {
            if (this.secondName.length > 0) {
                this.$refs.loginSecondName.textContent = ''
                this.secondNameError = false
            }
        },
        phone() {
            if (this.phone.length > 0) {
                this.$refs.loginPhone.textContent = ''
                this.loginFirstPhoneError = false
            }

        },
        email() {
            if (this.email.length > 0) {
                this.$refs.loginRegEmail.textContent = ''
                this.emailError = false
            }

        },
    },
    computed: {
        ...mapGetters({
            fastOrderProduct: 'order/fastOrderProduct',
            fastOrderLoading: 'order/fastOrderLoading',

        }),
    },
    methods: {
        ...mapMutations({
            showFastOrder: 'order/FAST_ORDER_POPUP',
        }),
        ...mapActions({
            fetchFastOrder: 'order/SEND_FAST_ORDER',

        }),
        hidePopup() {
            this.showFastOrder(false)
            document.getElementById('html').classList.remove('hide')
        },
        validationEmail() {
            let error = this.$refs.loginRegEmail
            let email = this.email
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (error) {
                error.textContent = ''
            }
            switch (true) {
                case email.length === 0:
                    error.append(this.$t('validation.required'));
                    this.emailError = true
                    break;
                case !regex.test(this.email):
                    error.append(this.$t('validation.email'));
                    this.emailError = true
                    break;
            }
        },
        send() {
            validation.methods.validation(this.$refs.loginName, this.name, 2)
            validation.methods.validation(this.$refs.loginSecondName, this.secondName, 2)
            validation.methods.validation(this.$refs.loginPhone, this.phone, 19)
            this.validationEmail()
            let obj = {
                product: {},
                user_info: {}
            }
            obj.product.id = this.fastOrderProduct.products.id
            obj.product.quantity = this.fastOrderProduct.amount

            obj.user_info.name = this.name
            obj.user_info.middle_name = this.secondName
            obj.user_info.phone = this.phone
            obj.user_info.email = this.email

            if (!this.nameError && !this.secondNameError && !this.loginFirstPhoneError && !this.emailError) {
                this.fetchFastOrder(obj).then(() => {
                    this.showFastOrder(false)
                    document.getElementById('html').classList.remove('hide')
                    this.$toasted.success(this.$t('personalOffice.orders.success'));
                })
            } else {
                console.log('error')
            }


        }
    }
}
