<template>
  <div class="button-loader loader--style8">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
    <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
               repeatCount="indefinite"/>
      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
               repeatCount="indefinite"/>
      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite"/>
    </rect>
      <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
               repeatCount="indefinite"/>
        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                 repeatCount="indefinite"/>
        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                 repeatCount="indefinite"/>
    </rect>
      <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
               repeatCount="indefinite"/>
        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                 repeatCount="indefinite"/>
        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                 repeatCount="indefinite"/>
    </rect>
  </svg>
  </div>
</template>
<script>
export default {
  name: 'button-loader',
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.button-loader {
  margin: 0 0 2em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: black;

  svg path,
  svg rect {
    fill: white;
  }
  svg{
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


</style>
