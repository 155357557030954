
export default {
  name: "badges",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {}
}
