//sections
import {mapActions, mapGetters, mapMutations} from 'vuex';
import specification from "@/modules/product-card/pages/specification/index.vue";

export default {
    name: "gable_canopy",
    components: {
        specification
    },
    data() {
        return {
            captureToggle: false,
            x: -50,
            y: 329,
            afterStartPositionX: 0,
            afterStartPositionY: 0,
            startAnimation: true,
            yellow: '#70B4D1',
            roofLeft: {
                width: 0.7,
                length: 0.7
            },
            roofRight: {
                width: 0.7,
                length: 0.7
            },
            roofLeftWidth: 2000,
            roofLeftLength: 2000,
            roofRightWidth: 2000,
            roofRightLength: 2000,

        }
    },
    mounted() {
        this.getProductions({calculator_type_slug: 'gable_canopy'}).then(() => {
            console.log(this.productAttributes.sizes, 'productAttributes');
            this.roofLeftWidth = this.productAttributes.sizes[0].scope
            this.roofLeftLength = this.productAttributes.sizes[0].length
            this.roofRightWidth = this.productAttributes.sizes[1].scope
            this.roofRightLength = this.productAttributes.sizes[1].length
            this.specificationList.specification.products = []
        })
    },
    created() {

    },
    watch: {
        roofLeftWidth() {
            this.$refs.errorProductD1.innerText = ''
            let obj = {}
            obj.roofLeftWidth = this.roofLeftWidth
            obj.roofLeftLength = this.roofLeftLength
            obj.roofRightWidth = this.roofRightWidth
            obj.roofRightLength = this.roofRightLength

            this.setRoofSize(obj)
            if (this.roofLeftWidth <= 2000 && this.roofLeftWidth) {
                this.roofLeft.width = (0.035 * this.roofLeftWidth) / 100
            }
            let result = (0.035 * this.roofLeftWidth) / 100
            this.roofLeft.width = result
            if (result <= 0.035) {
                this.roofLeft.width = 0.035
            }
            if (this.roofLeftWidth >= 2000) {
                this.roofLeft.width = 0.7 + this.roofLeftWidth / 100000
            }
            if (this.roofLeftWidth >= 50000) {
                this.roofLeft.width = 1.2
            }
        },
        roofLeftLength() {
            this.$refs.errorProductA1.innerText = ''
            let obj = {}
            obj.roofLeftWidth = this.roofLeftWidth
            obj.roofLeftLength = this.roofLeftLength
            obj.roofRightWidth = this.roofRightWidth
            obj.roofRightLength = this.roofRightLength

            this.setRoofSize(obj)
            if (this.roofLeftLength <= 2000 && this.roofLeftLength) {
                this.roofLeft.length = (0.035 * this.roofLeftLength) / 100
            }
            let result = (0.035 * this.roofLeftLength) / 100
            this.roofLeft.length = result
            if (result <= 0.035) {
                this.roofLeft.length = 0.035
            }
            if (this.roofLeftLength >= 2000) {
                this.roofLeft.length = 0.7 + this.roofLeftLength / 100000
            }
            if (this.roofLeftLength >= 50000) {
                this.roofLeft.length = 1.2
            }
        },
        roofRightWidth() {
            this.$refs.errorProductD2.innerText = ''
            let obj = {}
            obj.roofLeftWidth = this.roofLeftWidth
            obj.roofLeftLength = this.roofLeftLength
            obj.roofRightWidth = this.roofRightWidth
            obj.roofRightLength = this.roofRightLength

            this.setRoofSize(obj)
            if (this.roofRightWidth <= 2000 && this.roofRightWidth) {
                this.roofRight.width = (0.035 * this.roofRightWidth) / 100
            }
            let result = (0.035 * this.roofRightWidth) / 100
            this.roofRight.width = result
            if (result <= 0.035) {
                this.roofRight.width = 0.035
            }
            if (this.roofRightWidth >= 2000) {
                this.roofRight.width = 0.7 + this.roofRightWidth / 100000
            }
            if (this.roofRightWidth >= 50000) {
                this.roofRight.width = 1.2
            }
        },
        roofRightLength() {
            this.$refs.errorProductA2.innerText = ''
            let obj = {}
            obj.roofLeftWidth = this.roofLeftWidth
            obj.roofLeftLength = this.roofLeftLength
            obj.roofRightWidth = this.roofRightWidth
            obj.roofRightLength = this.roofRightLength

            this.setRoofSize(obj)
            if (this.roofRightLength <= 2000 && this.roofRightLength) {
                this.roofRight.length = (0.035 * this.roofRightLength) / 100
            }
            let result = (0.035 * this.roofRightLength) / 100
            this.roofRight.length = result
            if (result <= 0.035) {
                this.roofRight.length = 0.035
            }
            if (this.roofRightLength >= 2000) {
                this.roofRight.length = 0.7 + this.roofRightLength / 100000
            }
            if (this.roofRightLength >= 50000) {
                this.roofRight.length = 1.2
            }
        }
    },
    computed: {
        ...mapGetters({
            productAttributes: 'constructors/calculatorSpecifications',
            specificationList: 'constructors/specificationList',
            downloadLink: 'constructors/downloadLink',
            productsLoaded: 'constructors/productsLoaded',
            currentSpecification: 'constructors/currentSpecification',
            specificationsLoaded:'constructors/specificationsLoaded'
        })
    },
    methods: {
        ...mapActions({
            getProductions: 'constructors/CET_ARCH_SPECIFICATION',
            getSpecifications: 'constructors/GET_SPECIFICATIONS',
            downloadPdf: 'constructors/DOWNLOAD_PDF',
            upd: 'basket/UPDATE_BASKET_CURRENT',
        }),
        ...mapMutations({
            setRoofSize: 'constructors/SET_ROOF_SIZE',
            saveTogetherProducts: 'basket/ADD_TOGETHER_PRODUCTS',
            resetSpecification: 'constructors/RESET_SPECIFICATIONS'
        }),
        addInputAnimated(item, classList, textItem, animText, border1Class, border2, border2Class) {
            document.getElementById(border2).classList.add(border2Class)
            console.log(document.querySelector(`.${border2}`));

            document.getElementById(item).classList.add(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.add(animText)
            })
        },
        removeInputAnimated(item, classList, textItem, animText,  border1Class, border2, border2Class) {
            document.getElementById(border2).classList.remove(border2Class)

            document.getElementById(item).classList.remove(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.remove(animText)
            })
        },
        addToBasket() {
            this.specificationList.specification.products.forEach(el => {
                console.log(el, 'el');
                let obj = {}
                obj.id = el.id
                obj.select_type = el
                obj.select_type.amount = el.count
                obj.quantity = el.count
                this.saveTogetherProducts(obj)
            })
            this.upd().then(() => {
                this.$toasted.success(this.$t('basket.successAddBasket'));
            })
        },
        download() {
            const link = document.createElement("a");
            link.setAttribute('target', '_blank')
            link.href = this.specificationList.fileName.fileName
            link.download = 'specification.pdf';
            link.click();
        },
        mo: function (evt) {
            if (this.captureToggle) {
                // if (this.afterStartPositionX) {
                //     this.x = this.afterStartPositionX
                // }
                this.x = evt.x
                // if (evt.y < 365 && evt.y > 310) {
                this.y = evt.y
                // }
            }
        },
        captureOn() {
            this.$refs['scene-arch'].classList.add('handRotate')
            this.captureToggle = true
            this.startAnimation = false
        },
        captureOff() {
            this.$refs['scene-arch'].classList.remove('handRotate')
            // this.afterStartPositionX = this.x
            // this.afterStartPositionY = this.y
            this.captureToggle = false
            this.startAnimation = true
        },
        getNewProducts() {
            let obj = {}
            obj.squares = [
                {
                    scope: parseInt(this.roofLeftWidth),
                    length: parseInt(this.roofLeftLength),
                },
                {
                    scope: parseInt(this.roofRightWidth),
                    length: parseInt(this.roofRightLength)
                }
            ]
            obj.calculator_type_slug = 'gable_canopy'
            obj.type = 'calculate'
            if (this.roofRightWidth < 1000) {
                this.$refs.errorProductD2.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofLeftWidth < 1000) {
                this.$refs.errorProductD1.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofLeftLength < 1000) {
                this.$refs.errorProductA1.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofRightLength < 1000) {
                this.$refs.errorProductA2.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofLeftWidth >= 1000 && this.roofLeftLength >= 1000 && this.roofRightWidth >= 1000 && this.roofRightLength >= 1000) {
                this.getSpecifications(obj)
            }
        }
    }
}

