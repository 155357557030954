//sections
import {mapActions, mapGetters, mapMutations} from 'vuex';
import specification from "@/modules/product-card/pages/specification/index.vue";

export default {
    name: "single_pitched_canopy",
    components: {
        specification
    },
    data() {
        return {
            captureToggle: false,
            x: -50,
            y: 329,
            afterStartPositionX: 0,
            afterStartPositionY: 0,
            startAnimation: true,
            yellow: '#70B4D1',
            roof: {
                width: 0.7,
                length: 0.7
            },
            roofWidth: 2000,
            roofLength: 2000,
        }
    },
    mounted() {
        this.getProductions({calculator_type_slug: 'single_pitched_canopy'}).then(() => {
            this.roofWidth = this.productAttributes.sizes[0].scope
            this.roofLength = this.productAttributes.sizes[0].length
            this.specificationList.specification.products = []
        })
    },
    created() {

    },
    watch: {
        roofWidth() {
            this.$refs.errorProductA1.innerText = ''
            let obj = {}
            obj.width = this.roofWidth
            obj.length = this.roofLength

            this.setRoofSize(obj)
            if (this.roofWidth <= 2000 && this.roofWidth) {
                this.roof.width = (0.035 * this.roofWidth) / 100
            }
            let result = (0.035 * this.roofWidth) / 100
            this.roof.width = result
            if (result <= 0.035) {
                this.roof.width = 0.035
            }
            if (this.roofWidth >= 2000) {
                this.roof.width = 0.7 + this.roofWidth / 100000
            }
            if (this.roofWidth >= 50000) {
                this.roof.width = 1.2
            }
        },
        roofLength() {
            this.$refs.errorProductD1.innerText = ''
            let obj = {}
            obj.width = this.roofWidth
            obj.length = this.roofLength

            this.setRoofSize(obj)
            if (this.roofLength <= 2000 && this.roofLength) {
                this.roof.length = (0.035 * this.roofLength) / 100
            }
            let result = (0.035 * this.roofLength) / 100
            this.roof.length = result
            if (result <= 0.035) {
                this.roof.length = 0.035
            }
            if (this.roofLength >= 2000) {
                this.roof.length = 0.7 + this.roofLength / 100000
            }
            if (this.roofLength >= 50000) {
                this.roof.length = 1.2
            }
        },
    },
    computed: {
        ...mapGetters({
            productAttributes: 'constructors/calculatorSpecifications',
            specificationList: 'constructors/specificationList',
            downloadLink: 'constructors/downloadLink',
            productsLoaded: 'constructors/productsLoaded',
            currentSpecification: 'constructors/currentSpecification',
            specificationsLoaded: 'constructors/specificationsLoaded'
        })
    },
    methods: {
        ...mapActions({
            getProductions: 'constructors/CET_ARCH_SPECIFICATION',
            getSpecifications: 'constructors/GET_SPECIFICATIONS',
            downloadPdf: 'constructors/DOWNLOAD_PDF',
            upd: 'basket/UPDATE_BASKET_CURRENT',
        }),
        ...mapMutations({
            setRoofSize: 'constructors/SET_ROOF_SIZE',
            saveTogetherProducts: 'basket/ADD_TOGETHER_PRODUCTS',
        }),
        addInputAnimated(item, classList, textItem, animText, border1Class, border2, border2Class) {
            document.getElementById(border2).classList.add(border2Class)
            console.log(document.querySelector(`.${border2}`));

            document.getElementById(item).classList.add(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.add(animText)
            })
        },
        removeInputAnimated(item, classList, textItem, animText,  border1Class, border2, border2Class) {
            document.getElementById(border2).classList.remove(border2Class)

            document.getElementById(item).classList.remove(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.remove(animText)
            })
        },
        mo: function (evt) {
            if (this.captureToggle) {
                // if (this.afterStartPositionX) {
                //     this.x = this.afterStartPositionX
                // }
                this.x = evt.x
                // if (evt.y < 365 && evt.y > 310) {
                this.y = evt.y
                // }
            }
        },
        captureOn() {
            this.$refs['scene-flat'].classList.add('handRotate')
            this.captureToggle = true
            this.startAnimation = false
        },
        captureOff() {
            this.$refs['scene-flat'].classList.remove('handRotate')
            // this.afterStartPositionX = this.x
            // this.afterStartPositionY = this.y
            this.captureToggle = false
            this.startAnimation = true
        },
        addToBasket() {
            this.specificationList.specification.products.forEach(el => {
                console.log(el, 'el');
                let obj = {}
                obj.id = el.id
                obj.select_type = el
                obj.select_type.amount = el.count
                obj.quantity = el.count
                this.saveTogetherProducts(obj)
            })
            this.upd().then(() => {
                this.$toasted.success(this.$t('basket.successAddBasket'));
            })
        },
        download() {
            const link = document.createElement("a");
            link.setAttribute('target', '_blank')
            link.href = this.specificationList.fileName.fileName
            link.download = 'specification.pdf';
            link.click();
        },
        getNewProducts() {
            let obj = {}
            obj.squares = [
                {
                    scope: parseInt(this.roofWidth),
                    length: parseInt(this.roofLength)
                }
            ]
            obj.calculator_type_slug = 'single_pitched_canopy'
            obj.type = 'calculate'
            if (this.roofWidth >= 1000 && this.roofLength >= 1000) {
                this.getSpecifications(obj)
            }
            if (this.roofWidth < 1000) {
                this.$refs.errorProductA1.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofLength < 1000) {
                this.$refs.errorProductD1.innerText = 'Мінімальне значення 1000мм'
            }
        }
    }
}

