import {mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: "slider-badge",
  components: {
    VueSlickCarousel
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      bigSlider: {
        "infinite": true,
        "centerPadding": "0",
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "initialSlide": 0,
        // "fade": true,
        arrows: false,
        centerMode: true,
      },
    }
  },
  created() {
  },
  computed: {},
  methods: {
    showNext() {
      this.$refs.sliderBadge.next()
    },
    showPrev() {
      this.$refs.sliderBadge.prev()
    },
    ...mapMutations({}),

  }
}
