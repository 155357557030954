import {mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "specification",
  components: {},
  props: {
    specific: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {

  },
  computed: {
    ...mapGetters({})
  },

  methods: {
    ...mapMutations({})
  }
}
