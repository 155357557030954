export default {
  name: "main-banner",
  props: {
    item: {}

  },
  data() {
    return {}
  },
  mounted() {
    // this.item = JSON.parse(this.item)
  },
  created() {
  },
  methods: {}
}
