import {mapMutations} from "vuex";

export default {
  name: "image-text",
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    positionLeft: {
      type: Boolean,
      default: true
    },
    video: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapMutations({}),

  }
}
