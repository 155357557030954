import {mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import Vue from "vue";

export default {
    name: "before-after-slider",
    props: {
        item: {
            type: Object,
            default: () => {
            },
        },
    },
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            bigSlider: {
                "infinite": true,
                "centerPadding": "0",
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "speed": 1000,
                arrows: false,
                "responsive": [
                    {
                        "breakpoint": 800,
                        "settings": {
                            "vertical": true,
                        }
                    },

                ],
            }
        }
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        showNext() {
            this.$refs.beforeAfter.next()
        },
        showPrev() {
            this.$refs.beforeAfter.prev()
        },
    }
}
