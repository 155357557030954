import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
    name: "product-card",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },
        isLoad: false
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
        }),
        productStatusColor() {
            if (this.item.productStatus.key === 'in_stock') {
                return '#5ED84B'
            }
            if (this.item.productStatus.key === 'not_available') {
                return '#0e3ab6'
            }
            if (this.item.productStatus.key === 'order') {
                return '#171616'
            }
            if (this.item.productStatus.key === 'waiting') {
                return '#0e3ab6'
            }
        },
        oldPrice() {
            const productPrice = this.item.productPrice

            if (!productPrice.hasDiscount) {
                return '';
            }

            const unit = productPrice.baseUnit.title

            const price = productPrice.baseUnit.isM2
                ? productPrice.priceOld.priceM2
                : productPrice.priceOld.price

            return (price || 0) + ' грн/' + unit
        },
        currentPrice() {
            const productPrice = this.item.productPrice
            const unit = productPrice.baseUnit.title

            const price = productPrice.baseUnit.isM2
                ? productPrice.price.priceM2
                : productPrice.price.price

            return (price || 0) + ' грн/' + unit
        }
    },
    created() {


    },
    methods: {
        ...mapMutations({
            addToBasket: `basket/ADD_ITEM_TO_BASKET`,
        }),
        ...mapActions({
            getCurrentProduct: 'catalog/GET_PRODUCT',
            basketProductAction: 'basket/BASKET_PRODUCT_ACTION',
        }),
        addBasket() {
            if (this.item.inStock) {
                this.getCurrentProduct(this.item.slug).then(() => {
                    this.basketProductAction({type: 'increment', product_id: this.currentProduct.id})
                    this.$toasted.success(this.$t('basket.successAddBasket'));
                })
            }
        },
        changeImage(item, ref) {
            if (item.secondImage) {
                let test = this.$refs[ref]
                test.classList.add('fade')
                test.setAttribute('src', item.secondImage)

            }

        },
        deleteImage(item, ref) {
            let test = this.$refs[ref]
            test.classList.remove('fade')
            test.setAttribute('src', item.image)

        }
    },
}
