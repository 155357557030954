import {mapGetters, mapMutations} from "vuex";

//sections
export default {
  name: "table-section",
  components: {},
  props: {
    list: {
      type: Object,
      default: () => {}
    },

  },
  data() {
    return {

    }
  },
  created() {},
  computed: {
    ...mapGetters({})
  },

  methods: {
    ...mapMutations({})
  }
}
