import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
  LOADING_MANUAL_PAGE,
  LOADING_MANUALS, SET_MANUAl_PAGE,
  SET_MANUALS


} from '../mutation-types';

import {
  GET_MANUAL_PAGE,
  GET_MANUALS


} from '../action-types';
import axios from "axios";

const state = {
  manuals: [],
  manualsLoading: false,
  manualPage: '',
  manualPageLoading: false
};

const getters = {
  manuals: state => state.manuals,
  manualsLoading: state => state.manualsLoading,
  manualPage: state => state.manualPage,
  manualPageLoading: state => state.manualPageLoading
};

const actions = {


  [GET_MANUALS]: async ({commit}) => {
    commit(LOADING_MANUALS, true);
    try {
      const response = await $http.get(`v1/manuals`);
      commit(SET_MANUALS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_MANUALS, false);
    }
  },
  [GET_MANUAL_PAGE]: async ({commit}, slug) => {
    commit(LOADING_MANUAL_PAGE, true);
    try {
      const response = await $http.get(`v1/manual-pages/${slug}`);
      commit(SET_MANUAl_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_MANUAL_PAGE, false);
    }
  },

};

const mutations = {
  [SET_MANUALS](state, data) {
    state.manuals = data
  },
  [LOADING_MANUALS](state, status) {
    state.manualsLoading = status
  },
  [SET_MANUAl_PAGE](state, data) {
    state.manualPage = data
  },
  [LOADING_MANUAL_PAGE](state, status) {
    state.manualPageLoading = status
  }

};

export default {
  state,
  getters,
  actions,
  mutations,
};
