import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
    name: "product-comment",
    props: {},
    data() {
        return {
            payload: {
                message: '',
                product_id: '',
                stars: 0,
                validStars: false,
                validMessage: false
            }
        }
    },
    watch: {
        'payload.stars'() {
            this.$refs.commentError.textContent = ''
            this.validStars = false
        },
        'payload.message'() {
            this.$refs.commentError.textContent = ''
            this.validMessage = false
        }
    },
    created() {
        this.payload.product_id = this.currentProduct.id
        if (this.questionId) {
            this.payload.parent_id = this.questionId
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            questionId: 'card/questionId'
        })
    },
    methods: {
        ...mapActions({
            sendQuestion: 'card/WRITE_COMMENT',
        }),
        ...mapMutations({
            closeCommentPopup: 'card/SHOW_COMMENT_POPUP',
        }),
        sendUserQuestion() {
            let errorText = this.$refs.commentError
            if (!this.payload.stars && !this.questionId) {
                this.validStars = true
                errorText.textContent = 'rating error'
            }
            if (this.questionId) {
                this.validStars = false
            }
            if (!this.payload.message.length) {
                this.validMessage = true
                errorText.textContent = this.$t('callback.enterText')
            }
            if (this.payload.message.length <= 10) {
                this.validMessage = true
                errorText.textContent = this.$t('validation.minLength', {'size': 10})
            }
            this.payload.stars = parseInt(this.payload.stars)
            if (!this.validMessage && !this.validStars) {
                if (this.questionId) {
                    delete this.payload.stars
                }
                this.sendQuestion(this.payload).then(() => {
                    this.$toasted.success(this.$t('productCard.comments.success'));
                    this.closeCommentPopup(false)
                    document.getElementById('html').classList.remove('hide')
                }).catch(error => {
                    this.$toasted.error('error', error);
                })
            }
        }
    },
}
