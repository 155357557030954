import {mapMutations, mapGetters} from "vuex";
import {chunk} from 'lodash'
import {directive as onClickaway} from 'vue-clickaway';
import Vue from "vue";

export default {
  name: "cover",
  directives: {
    onClickaway: onClickaway,
  },
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      groups: [],
      isPlayVideo: false,
      videoId: '',
    }
  },
  beforeUpdate() {
    let list = [], size = this.list.length;
    list[size - 1] = 5
    let groupSize = 6;
    this.groups = chunk(this.list, groupSize)
  },
  mounted() {
    let list = [], size = this.list.length;
    list[size - 1] = 5
    let groupSize = 6;
    this.groups = chunk(this.list, groupSize)
  },
  created() {
    let list = [], size = this.list.length;
    list[size - 1] = 5
    let groupSize = 6;
    this.groups = chunk(this.list, groupSize)
  },
  computed: {
    ...mapGetters({
      pageContent: 'setting/pageContent'
    })
  },
  methods: {
    ...mapMutations({}),
    goToProjects() {
      this.$router.push({name: 'projects'}).catch(() => {
        console.log()
      })
    },
    playVideo(item) {
      this.isPlayVideo = !this.isPlayVideo
      this.videoId = item.id
    },
    away: function () {
      this.isPlayVideo = !this.isPlayVideo
      this.videoId = ''
    },
    goTo(item){
      this.$router.push({name:'project', params:{slug: item.targetSource.slug}})
    }
  }
}
