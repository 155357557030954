import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
    name: "profile-document",
    components: {},
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    created() {
        // ${payload.document_id}?type=${payload.type}&sub_type=${payload.sub_type}
    },
    mounted() {
        this.getCurrentDocument({
            document_id: this.documentPopup.data.id,
            type: this.documentPopup.data.typeDetails.type,
            sub_type: this.documentPopup.data.typeDetails.subType,
        })

    },
    watch: {},
    computed: {
        ...mapGetters({
            documentPopup: 'profile/documentPopup',
            currentDocument: 'profile/currentDocument',
            documentLoading: 'profile/documentLoading',
            documentPdf: 'profile/documentPdf'
        })
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            getCurrentDocument: 'profile/GET_CURRENT_DOCUMENT',
            generatePdf: 'profile/GENERATE_DOCUMENT_PDF'
        }),
        download() {
            this.generatePdf({
                document_id: this.documentPopup.data.id,
                type: this.documentPopup.data.typeDetails.type,
                sub_type: this.documentPopup.data.typeDetails.subType,
            }).then(() => {
                const link = document.createElement("a");
                link.setAttribute('target', '_blank')
                link.href = this.documentPdf.source
                link.download = 'specification.pdf';
                link.click();
            })

        },

    }
}
