//settings
import {GET_ADDRESS_LIST} from './action-types';
//setting
export const SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING'
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const SET_REQUESTS_FLAG = 'SET_REQUESTS_FLAG';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT'
export const CHANGE_PAGE_LOADING = 'CHANGE_PAGE_LOADING'
export const SET_SEND_FEEDBACK = 'SET_SEND_FEEDBACK'
export const SHOW_THANKS_POPUP = 'SHOW_THANKS_POPUP'
export const SET_VIDEO_GALLERY = 'SET_VIDEO_GALLERY'
export const SET_VIDEOS_PAGE = 'SET_VIDEOS_PAGE'
export const SET_CONTACTS_FOOTER = 'SET_CONTACTS_FOOTER'
export const SET_CONTACTS_HEADER = 'SET_CONTACTS_HEADER'
export const SEND_FEEDBACK_LOADING = 'SEND_FEEDBACK_LOADING'
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY'
export const SET_SITEMAP = 'SET_SITEMAP'

//order
export const SET_LOGIN_SMS_CODE = 'SET_LOGIN_SMS_CODE'
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS'
export const NOVA_POSHTA_SET_TOWNS_LOADING = 'NOVA_POSHTA_SET_TOWNS_LOADING'
export const SET_CONTACT_DATA = 'SET_CONTACT_DATA'
export const SET_DATA_OTHER_RECIPIENT = 'SET_DATA_OTHER_RECIPIENT'
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS'
export const NOVA_POSHTA_SET_DEPARTMENS_LOADING = 'NOVA_POSHTA_SET_DEPARTMENS_LOADING'
export const CAT_SET_TOWNS = 'CAT_SET_TOWNS'
export const CAT_SET_TOWNS_LOADING = 'CAT_SET_TOWNS_LOADING'
export const CAT_SET_DEPARTMENS = 'CAT_SET_DEPARTMENS'
export const CAT_SET_DEPARTMENS_LOADING = 'CAT_SET_DEPARTMENS_LOADING'
export const DELIVERY_SET_TOWNS = 'DELIVERY_SET_TOWNS'
export const DELIVERY_SET_TOWNS_LOADING = 'DELIVERY_SET_TOWNS_LOADING'
export const DELIVERY_SET_DEPARTMENS = 'DELIVERY_SET_DEPARTMENS'
export const DELIVERY_SET_DEPARTMENS_LOADING = 'DELIVERY_SET_DEPARTMENS_LOADING'
export const SET_SERVICES_LIST = 'SET_SERVICES_LIST'
export const LOADING_SERVICES_LIST = 'LOADING_SERVICES_LIST'
export const SET_PAYMENTS_LIST = 'SET_PAYMENTS_LIST'
export const PAYMENTS_LIST_LOADING = 'PAYMENTS_LIST_LOADING'
export const SET_DELIVERIES_LIST = 'SET_DELIVERIES_LIST'
export const DELIVERIES_LIST_LOADING = 'DELIVERIES_LIST_LOADING'
export const SET_CALCULATION_TYPE = 'SET_CALCULATION_TYPE'
export const CALCULATION_TYPE_LOADING = 'CALCULATION_TYPE_LOADING'
export const SET_IPN = 'SET_IPN'
export const RESET_IPN = 'RESET_IPN'
export const SET_IPN_LOADING = 'SET_IPN_LOADING'
export const SELECT_DELIVERY = 'SELECT_DELIVERY'
export const SELECT_PAYMENT = 'SELECT_PAYMENT'
export const USER_DATA_FILLED = 'USER_DATA_FILLED'
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDER_LOADING = 'SET_ORDER_LOADING'
export const SHOW_SUCCESS_POPUP = 'SHOW_SUCCESS_POPUP'
export const FAST_ORDER_POPUP = 'FAST_ORDER_POPUP'
export const SET_PRODUCT_FOR_FAST_ORDER = 'SET_PRODUCT_FOR_FAST_ORDER'
export const SHOW_HEADER_BASKET = 'SHOW_HEADER_BASKET'
export const FAST_ORDER_LOADING = 'FAST_ORDER_LOADING'
export const RESET_PAYMENT_DATA = 'RESET_PAYMENT_DATA'
export const SHOW_SUCCESS_PAYMENT_POPUP = 'SHOW_SUCCESS_PAYMENT_POPUP'
export const SET_TOWNS_LIST = 'SET_TOWNS_LIST'
export const SET_DEPARTMENTS_LIST = 'SET_DEPARTMENTS_LIST'
export const SET_ADDRESS_LIST = 'SET_ADDRESS_LIST'
export const RESET_DELIVERY_LIST = 'RESET_DELIVERY_LIST'
export const ADDRESS_LIST_LOADING = 'ADDRESS_LIST_LOADING'
export const SHOW_ORDER_COLOR_POPUP = 'SHOW_ORDER_COLOR_POPUP'

//catalog
export const SET_CATEGORY = 'SET_CATEGORY'
export const CHANGE_CATEGORY_LOADING = 'CHANGE_CATEGORY_LOADING'
export const SET_ONE_CATEGORY = 'SET_ONE_CATEGORY'
export const CHANGE_ONE_CATEGORY_LOADING = 'CHANGE_ONE_CATEGORY_LOADING'
export const SET_PRODUCT = 'SET_PRODUCT'
export const CHANGE_PRODUCT_LOADING = 'CHANGE_PRODUCT_LOADING'
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY'
export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS'
export const PRODUCT_FILTERS_LOADING = 'PRODUCT_FILTERS_LOADING'
export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS'
export const FILTERED_PRODUCTS_LOADING = 'FILTERED_PRODUCTS_LOADING'
export const SAVE_ATTRIBUTES_VALUE = 'SAVE_ATTRIBUTES_VALUE'
export const SKIP_PAGINATION = 'SKIP_PAGINATION'
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST'
export const SEARCH_LIST_LOADING = 'SEARCH_LIST_LOADING'
export const CLEAR_SEARCH_LIST = 'CLEAR_SEARCH_LIST'
export const SHOW_MOBILE_FILTERS = 'SHOW_MOBILE_FILTERS'

//card
export const SET_QUESTION = 'SET_QUESTION'
export const CHANGE_QUESTION_LOADING = 'CHANGE_QUESTION_LOADING'
export const SHOW_QUESTION_POPUP = 'SHOW_QUESTION_POPUP'
export const SET_QUESTION_ID = 'SET_QUESTION_ID'
export const CHANGE_COMMENT_LOADING = 'CHANGE_COMMENT_LOADING'
export const SET_COMMENT = 'SET_COMMENT'
export const SHOW_COMMENT_POPUP = 'SHOW_COMMENT_POPUP'
export const SET_PRODUCT_WITH_ATTRIBUTE = 'SET_PRODUCT_WITH_ATTRIBUTE'
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING'
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE'

//auth
export const LOADING_USER_DATA = 'LOADING_USER_DATA'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SHOW_REGISTER_POPUP = 'SHOW_REGISTER_POPUP'
export const SET_REGISTER_SEND_CODE_LOADING = 'SET_REGISTER_SEND_CODE_LOADING'
export const SET_REGISTER_CODE = 'SET_REGISTER_CODE'
export const SET_REGISTER_CHECK_CODE_LOADING = 'SET_REGISTER_CHECK_CODE_LOADING'
export const SET_REGISTER_CHECK_CODE = 'SET_REGISTER_CHECK_CODE'
export const SET_TOKEN = 'SET_TOKEN'
export const RESET_TOKEN = 'RESET_TOKEN'
export const SHOW_AUTH_POPUP = 'SHOW_AUTH_POPUP'
export const SHOW_AUTH_POPUP_THANKS = 'SHOW_AUTH_POPUP_THANKS'

//manuals
export const SET_MANUALS = 'SET_MANUALS'
export const LOADING_MANUALS = 'LOADING_MANUALS'
export const SET_MANUAl_PAGE = 'SET_MANUAl_PAGE'
export const LOADING_MANUAL_PAGE = 'LOADING_MANUAL_PAGE'

//articles
export const SET_ARTICLES = 'SET_ARTICLES'
export const LOADING_ARTICLES = 'LOADING_ARTICLES'
export const SET_ARTICLE_PAGE = 'SET_ARTICLE_PAGE'
export const LOADING_ARTICLE_PAGE = 'LOADING_ARTICLE_PAGE'
export const SET_ARTICLES_PAGE = 'SET_ARTICLES_PAGE'

//projects
export const SET_PROJECTS = 'SET_PROJECTS'
export const PROJECTS_LOADING = 'PROJECTS_LOADING'
export const SET_FILTERS = 'SET_FILTERS'
export const FILTERS_LOADING = 'FILTERS_LOADING'
export const SET_ONE_PROJECT = 'SET_ONE_PROJECT'
export const ONE_PROJECT_LOADING = 'ONE_PROJECT_LOADING'
export const SET_PROJECT_PAGE = 'SET_PROJECT_PAGE'

//contacts
export const SET_CONTACTS = 'SET_CONTACTS'
export const CONTACTS_LOADING = 'CONTACTS_LOADING'

//landings
export const SET_LANDING = 'SET_LANDING'
export const LENDING_LOADING = 'L/${payload.constructorId}/buildings/${payload.buildingId}ENDING_LOADING'
export const ORDER_TRIAL_BATCH = 'ORDER_TRIAL_BATCH'

//basket
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET'
export const REMOVE_ITEM_FROM_BASKET = 'REMOVE_ITEM_FROM_BASKET'
export const SET_BASKET_COUNT = 'SET_BASKET_COUNT'
export const INCREMENT_BASKET_LIST_COUNT = 'INCREMENT_BASKET_LIST_COUNT'
export const DECREMENT_BASKET_LIST_COUNT = 'DECREMENT_BASKET_LIST_COUNT'
export const SET_BASKET_LIST_COUNT = 'SET_BASKET_LIST_COUNT'
export const SET_BASKET_LOADING = 'SET_BASKET_LOADING'
export const SET_BASKET = 'SET_BASKET'
export const SET_SERVICES = 'SET_SERVICES'
export const CHANGE_BASKET_COUNT = 'CHANGE_BASKET_COUNT'
export const SET_PRODUCT_COUNT = 'SET_PRODUCT_COUNT'
export const ADD_TOGETHER_PRODUCTS = 'ADD_TOGETHER_PRODUCTS'
export const RESET_BASKET = 'RESET_BASKET'
export const BASKET_COUNT = 'BASKET_COUNT'
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES'
export const ADD_GIFT_PRODUCT = 'ADD_GIFT_PRODUCT'

//constructors
export const SET_CONSTRUCTORS = 'SET_CONSTRUCTORS'
export const SET_CONSTRUCTORS_PAGE = 'SET_CONSTRUCTORS_PAGE'
export const LOADING_CONTENT = 'LOADING_CONTENT'
export const SET_BUILDING_ELEMENTS = 'SET_BUILDING_ELEMENTS'
export const SET_CURRENT_SPECIFICATION = 'SET_CURRENT_SPECIFICATION'
export const SET_SNOW_LOAD_CATEGORIES = 'SET_SNOW_LOAD_CATEGORIES'
export const SET_CURRENT_SNOW_CALCULATOR = 'SET_CURRENT_SNOW_CALCULATOR'
export const SET_LOADING_CALCULATOR = 'SET_LOADING_CALCULATOR'
export const SET_LOADING_BUILDINGS = 'SET_LOADING_BUILDINGS'
export const SET_CONSTRUCTOR_LOADING = 'SET_CONSTRUCTOR_LOADING'

//profile
export const SET_USER_DATA_PROFILE = 'SET_USER_DATA_PROFILE'
export const SET_LEGAL_USERS = 'SET_LEGAL_USERS'
export const SET_OWNERSHIP_TYPES = 'SET_OWNERSHIP_TYPES'
export const SET_CURRENT_ORDERS = 'SET_CURRENT_ORDERS'
export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS'
export const SET_DISCOUNTS = 'SET_DISCOUNTS'
export const SET_DISCOUNT_DESCRIPTION = 'SET_DISCOUNT_DESCRIPTION'
export const SET_CATALOG_GROUPS = 'SET_CATALOG_GROUPS'
export const SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS'
export const SET_SHOWING_RETAIL_PRICE = 'SET_SHOWING_RETAIL_PRICE'
export const SET_USER_FULL_NAME = 'SET_USER_FULL_NAME'
export const SET_IS_CREATED_LEGAL = 'SET_IS_CREATED_LEGAL'
export const PROFILE_LENDING_LOADING = 'PROFILE_LENDING_LOADING'
export const LOADING_BUTTON_LOADER = 'LOADING_BUTTON_LOADER'
export const RESET_PRODUCT_GROUPS = 'RESET_PRODUCT_GROUPS'
export const SET_DOCUMENT_POPUP = 'SET_DOCUMENT_POPUP'
export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT'
export const CURRENT_DOCUMENT_LOADING='CURRENT_DOCUMENT_LOADING'
export const SET_DOCUMENT_PDF = 'SET_DOCUMENT_PDF'

export const SET_CALCULATORS = 'SET_CALCULATORS'
export const SET_ARCH_SPECIFICATION = 'SET_ARCH_SPECIFICATION'
export const SET_SPECIFICATIONS = 'SET_SPECIFICATIONS'
export const SET_ROOF_SIZE = 'SET_ROOF_SIZE'
export const SHOW_MESSAGE_POPUP = 'SHOW_MESSAGE_POPUP'
export const RESET_SPECIFICATIONS = 'RESET_SPECIFICATIONS'
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED'
export const SPECIFICATIONS_LOADED = 'SPECIFICATIONS_LOADED'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'

//pricing
export const SET_PRICING_LIST = 'SET_PRICING_LIST'

