import {mapMutations} from "vuex";

export default {
  name: "simple-items",
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    numbered: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapMutations({}),
  }
}
