import {$http} from '@/utils/https'
import axios from 'axios'

import {
    LOADING_USER_DATA,
    SET_REGISTER_CODE,
    SET_USER_DATA,
    SHOW_REGISTER_POPUP,
    SET_REGISTER_SEND_CODE_LOADING,
    SET_REGISTER_CHECK_CODE,
    SET_REGISTER_CHECK_CODE_LOADING,
    SET_TOKEN, RESET_TOKEN, SHOW_AUTH_POPUP, SHOW_AUTH_POPUP_THANKS
} from '../mutation-types';

import {
    FETCH_USER_DATA,
    REGISTER_SEND_CODE,
    REGISTER_CHECK_CODE, LOGOUT
} from '../action-types';

const state = {
    isAuthenticated: false,
    loadedUserData: false,
    userData: '',
    showRegisterPopup: false,
    setRegisterCodeLoading: false,
    setRegisterCode: '',
    setRegisterCheckCode: '',
    setRegisterCheckCodeLoading: false,
    showAuthPopup: false,
    showAuthThanksPopup: false
};

const getters = {
    loadedUserData: state => state.loadedUserData,
    userData: state => state.userData,
    showRegisterPopup: state => state.showRegisterPopup,
    setRegisterCodeLoading: state => state.setRegisterCodeLoading,
    setRegisterCode: state => state.setRegisterCode,
    setRegisterCheckCode: state => state.setRegisterCheckCode,
    setRegisterCheckCodeLoading: state => state.setRegisterCheckCodeLoading,
    isAuthenticated: state => state.isAuthenticated,
    showAuthPopup: state => state.showAuthPopup,
    showAuthThanksPopup: state => state.showAuthThanksPopup
};

const actions = {
    [FETCH_USER_DATA]: async ({commit}) => {
        try {
            commit(LOADING_USER_DATA, true);
            const response = await $http.get('v1/profile/me');
            commit(SET_USER_DATA, response.data.data);
            return response.data.data
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_USER_DATA, false);
        }
    },
    [REGISTER_SEND_CODE]: async ({commit}, payload) => {
        try {
            commit(SET_REGISTER_SEND_CODE_LOADING, true);
            const response = await $http.post('v1/auth/send-code', payload);
            commit(SET_REGISTER_CODE, response.data);

            return response;
        } catch (e) {
            throw e;
        } finally {
            commit(SET_REGISTER_SEND_CODE_LOADING, false);
        }
    },
    [REGISTER_CHECK_CODE]: async ({commit}, payload) => {
        try {
            commit(SET_REGISTER_CHECK_CODE_LOADING, true);
            const response = await $http.post('v1/auth/check-code', payload);
            commit(SET_REGISTER_CHECK_CODE, response.data);
            commit(SET_TOKEN, response.data.token)
            return response;
        } catch (e) {
            throw e;
        } finally {
            commit(SET_REGISTER_CHECK_CODE_LOADING, false);
        }
    },
    [LOGOUT]: async ({commit}) => {
        try {
            await $http.post('v1/auth/logout');
            commit(RESET_TOKEN);
        } catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [SET_USER_DATA](state, data) {
        state.userData = data
    },
    [SHOW_REGISTER_POPUP](state, status) {
        state.showRegisterPopup = status
    },
    [SET_REGISTER_SEND_CODE_LOADING](state, status) {
        state.setRegisterCodeLoading = status
    },
    [SET_REGISTER_CHECK_CODE_LOADING](state, status) {
        state.setRegisterCheckCodeLoading = status
    },
    [SET_REGISTER_CODE](state, data) {
        state.setRegisterCode = data
    },
    [SET_REGISTER_CHECK_CODE](state, data) {
        state.setRegisterCheckCode = data
    },
    [SET_TOKEN](state, data) {
        localStorage.setItem('user_token', data.token);
        state.isAuthenticated = true
    },
    [RESET_TOKEN](state) {
        localStorage.setItem('user_token', '');
        state.isAuthenticated = false
    },
    [SHOW_AUTH_POPUP](state, status) {
        state.showAuthPopup = status
    },
    [SHOW_AUTH_POPUP_THANKS](state, status) {
        state.showAuthThanksPopup = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};


