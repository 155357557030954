import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import { translation } from '@/helpers/translation'


const proxy = {
  getTranslation: async () => {
    const response = await store.dispatch('setting/GET_TRANSLATIONS');
    Vue.use(response, translation);
    return response;
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'ua-UA',
    mixins: [
      translation
    ],
    languages: [
      {
        name: 'Ukranian',
        title: 'Ua.',
        key: 'uk',
        code: 'ua-UA',
        urlPrefix: 'uk',
        translationKey: 'uk'
      },
      {
        name: 'Russian',
        title: 'Rus.',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      },
    ],
    translations: proxy,
  },
});
