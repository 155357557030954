import {
  SHOW_GLOBAL_LOADER,
  SET_REQUESTS_FLAG
} from '@/store/mutation-types';

const state = {
  globalLoader: false,
  globalRequestsFlag: false
};

const getters = {
  globalLoader: state => state.globalLoader,
  globalRequestsFlag: state => state.globalRequestsFlag,
};

const mutations = {
  [SHOW_GLOBAL_LOADER](state, status) {
    state.globalLoader = status
  },
  [SET_REQUESTS_FLAG](state, status) {
    state.globalRequestsFlag = status
    state.globalLoader = status
  },
};

export default {
  state,
  getters,
  mutations,
};
