import {mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: "slider-center-mode",
  components: {
    VueSlickCarousel
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      bigSlider: {
        "centerMode": true,
        "centerPadding": "300px",
        "focusOnSelect": true,
        "infinite": true,
        "slidesToShow": 1,
        "speed": 500,
        arrows: false,
        "responsive": [
          {
            "breakpoint": 1400,
            "settings": {
              "centerPadding": "200px",
            }
          },
          {
            "breakpoint": 1200,
            "settings": {
              "centerPadding": "150px",
            }
          },
          {
            "breakpoint": 800,
            "settings": {
              "centerPadding": "100px",
            }
          },
          {
            "breakpoint": 550,
            "settings": {
              "centerPadding": "50px",
            }
          }
        ]
      },
    }
  },
  created() {
  },
  computed: {},
  methods: {
    showNext() {
      this.$refs.sliderCenter.next()
    },
    showPrev() {
      this.$refs.sliderCenter.prev()
    },
    ...mapMutations({}),

  }
}
