import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
    LENDING_LOADING, ORDER_TRIAL_BATCH,
    SET_LANDING


} from '../mutation-types';

import {
    GET_LENDING
} from '../action-types';
import axios from "axios";

const state = {
    content: '',
    landingLoading: false,
    orderTrialBatch: false
};

const getters = {
    landingContent: state => state.content,
    landingLoading: state => state.landingLoading,
    orderTrialBatch: state => state.orderTrialBatch
};

const actions = {


    [GET_LENDING]: async ({commit}, slug) => {
        commit(LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/page/${slug}`);
            commit(SET_LANDING, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(LENDING_LOADING, false);
        }
    },


};

const mutations = {
    [SET_LANDING](state, data) {
        state.content = data
    },
    [LENDING_LOADING](state, status) {
        state.landingLoading = status
    },
    [ORDER_TRIAL_BATCH](state, status) {
        state.orderTrialBatch = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
