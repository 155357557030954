import {$http} from '@/utils/https'
import axios from 'axios'

import {
  CHANGE_COMMENT_LOADING,
  CHANGE_QUESTION_LOADING,
  SET_COMMENT,
  SET_QUESTION,
  SET_QUESTION_ID,
  SHOW_COMMENT_POPUP,
  SHOW_QUESTION_POPUP,
  SET_PRODUCT_WITH_ATTRIBUTE, SET_PRODUCT_LOADING, SET_DELIVERY_TYPE
} from '../mutation-types';

import {
  WRITE_QUESTION,
  WRITE_COMMENT, GET_PRODUCT_WITH_ATTRIBUTE, GET_DELIVERY_TYPE
} from '../action-types';

const state = {
  loadingQuestion: false,
  question: '',
  showQuestionPopup: false,
  questionId: '',
  comment: '',
  loadingComment: false,
  showCommentPopup: false,
  setProduct: '',
  productLoading: false,
  deliveriesType: ''
};

const getters = {
  setProduct: state => state.setProduct,
  loadingQuestion: state => state.loadingQuestion,
  question: state => state.question,
  showQuestionPopup: state => state.showQuestionPopup,
  questionId: state => state.questionId,
  comment: state => state.comment,
  loadingComment: state => state.loadingComment,
  showCommentPopup: state => state.showCommentPopup,
  productLoading: state => state.productLoading,
  deliveriesType: state => state.deliveriesType
};

const actions = {
  [GET_DELIVERY_TYPE]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/deliveries`);
      commit(SET_DELIVERY_TYPE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_PRODUCT_WITH_ATTRIBUTE]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/catalog/product/${payload.slug}/similar?${payload.string}`);
      commit(SET_PRODUCT_WITH_ATTRIBUTE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [WRITE_QUESTION]: async ({commit}, payload) => {
    commit(CHANGE_QUESTION_LOADING, true);
    try {
      const response = await $http.post(`v1/catalog/question`, payload);
      commit(SET_QUESTION, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_QUESTION_LOADING, false);
    }
  },
  [WRITE_COMMENT]: async ({commit}, payload) => {
    commit(CHANGE_COMMENT_LOADING, true);
    try {
      const response = await $http.post(`v1/catalog/comment`, payload);
      commit(SET_COMMENT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_COMMENT_LOADING, false);
    }
  },
};

const mutations = {
  [CHANGE_QUESTION_LOADING](state, status) {
    state.productCount = status
  },
  [SET_QUESTION](state, data) {
    state.question = data
  },
  [SHOW_QUESTION_POPUP](state, status) {
    state.showQuestionPopup = status
  },
  [SET_QUESTION_ID](state, data) {
    state.questionId = data
  },
  [CHANGE_COMMENT_LOADING](state, status) {
    state.loadingComment = status
  },
  [SET_COMMENT](state, data) {
    state.comment = data
  },
  [SHOW_COMMENT_POPUP](state, status) {
    state.showCommentPopup = status
  },
  [SET_PRODUCT_WITH_ATTRIBUTE](state, data) {
    state.setProduct = data
  },
  [SET_PRODUCT_LOADING](state, status) {
    state.productLoading = status
  },
  [SET_DELIVERY_TYPE](state, data) {
    state.deliveriesType = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
