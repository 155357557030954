export default {
  name: 'timer',
  data() {
    return {
      timer: "",
      wordString: {},
      start: this.starttime,
      end: this.endtime,
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      message: "",
      statusType: "",
      statusText: "",
    }
  },
  props: ['starttime', 'endtime', 'trans'],
  components: {},
  created: function () {
    this.wordString = JSON.parse(this.trans);
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();

    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    /**
     * @param {number} number
     * @param {string} words
     */
    declensionWord(number, words) {
      let arrWords = words.split('|')
      let indexes = [2, 0, 1, 1, 1, 2]
      let index = (4 < (number % 100) && (number % 100) < 20)
        ? 2
        : indexes[Math.min(number % 10, 5)]
      return arrWords[index]
    },
    /**
     * @param {number} start
     * @param {number} end
     */
    timerCount: function (start, end) {
      let now = new Date().getTime();

      let distance = start - now;
      let passTime = end - now;

      if (distance < 0 && passTime < 0) {
        this.message = this.wordString.expired;
        this.statusType = "expired";
        this.statusText = this.wordString.status.expired;
        clearInterval(this.interval);
        return;

      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
        // this.message = this.wordString.running;
        // this.statusType = "running";
        // this.statusText = this.wordString.status.running;

      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
        // this.message = this.wordString.upcoming;
        // this.statusType = "upcoming";
        // this.statusText = this.wordString.status.upcoming;
      }
    },
    /** @param {number} dist */
    calcTime: function (dist) {
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
  }
}
