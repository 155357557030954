import {mapMutations} from "vuex";

export default {
  name: "two-images",
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapMutations({}),
  }
}
