//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// set auth status
if (localStorage.getItem('user_token')) {
  store.state.auth.isAuthenticated = true
}

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});

// const eventHub = new Vue()


Vue.mixin({
  data: function () {
    return {
      // eventHub: eventHub
    }
  }
})
