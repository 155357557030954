import {$http} from '@/utils/https'

import {
    LOADING_BUTTON_LOADER,
    PROFILE_LENDING_LOADING,
    SET_CATALOG_GROUPS,
    SET_CURRENT_ORDERS,
    SET_DISCOUNT_DESCRIPTION,
    SET_DISCOUNTS,
    SET_HISTORY_ORDERS,
    SET_IS_CREATED_LEGAL,
    SET_LEGAL_USERS,
    SET_OWNERSHIP_TYPES,
    SET_PRODUCT_GROUPS,
    SET_SHOWING_RETAIL_PRICE,
    SET_USER_DATA_PROFILE,
    SET_USER_FULL_NAME,
    RESET_PRODUCT_GROUPS,
    SET_DOCUMENTS,
    SET_DOCUMENT_POPUP,
    SET_CURRENT_DOCUMENT, CURRENT_DOCUMENT_LOADING, SET_DOCUMENT_PDF

} from '../mutation-types';

import {
    CREATE_LEGAL_USER,
    GET_LEGAL_USERS,
    GET_OWNERSHIP_TYPES,
    GET_USER_DATA,
    UPDATE_LEGAL_USER,
    UPDATE_USER_DATA,
    GET_CURRENT_ORDERS,
    GET_HISTORY_ORDERS,
    GET_DISCOUNTS,
    GET_CATALOG,
    GET_DISCOUNT_DESCRIPTION,
    LOGOUT,
    GET_PRODUCT_GROUP,
    GET_GREENHOUSE,
    GET_DOCUMENTS,
    GET_CURRENT_DOCUMENT,
    GENERATE_DOCUMENT_PDF
} from '../action-types';

const state = {
    userInfo: {
        name: '',
        lastName: '',
        middleName: '',
        bonuses: {}
    },
    userContacts: {
        email: '',
        phone: ''
    },
    legalUsers: [],
    ownershipTypes: [],
    isLoadingProfile: false,
    isLoadingButtonLoader: false,
    currentOrders: [],
    historyOrders: [],
    discounts: [],
    discountDescription: {},
    catalogGroups: {},
    productGroups: [],
    isShowRetailPrice: false,
    userFullName: {lastName: '', name: ''},
    creatingNewLegalSuccess: false,
    userDocuments: [],
    documentPopup: '',
    currentDocument: '',
    documentLoading: false,
    documentPdf:''
};

const getters = {
    documentPdf:state=>state.documentPdf,
    documentLoading: state => state.documentLoading,
    currentDocument: state => state.currentDocument,
    documentPopup: state => state.documentPopup,
    userDocuments: state => state.userDocuments,
    userInfo: state => state.userInfo,
    userContacts: state => state.userContacts,
    legalUsers: state => state.legalUsers,
    ownershipTypes: state => state.ownershipTypes,
    isLoadingProfile: state => state.isLoadingProfile,
    isLoadingButtonLoader: state => state.isLoadingButtonLoader,
    currentOrders: state => state.currentOrders,
    historyOrders: state => state.historyOrders,
    discounts: state => state.discounts,
    discountDescription: state => state.discountDescription,
    catalogGroups: state => state.catalogGroups,
    productGroups: state => state.productGroups,
    isShowRetailPrice: state => state.isShowRetailPrice,
    userFullName: state => state.userFullName,
    creatingNewLegalSuccess: state => state.creatingNewLegalSuccess,
};

const actions = {


    [GENERATE_DOCUMENT_PDF]: async ({commit}, payload) => {
        // commit(CURRENT_DOCUMENT_LOADING, true);
        try {
            const response = await $http.post(`v1/profile/documents/${payload.document_id}/generate-pdf?type=${payload.type}&sub_type=${payload.sub_type}`);
            console.log(response);
            commit(SET_DOCUMENT_PDF, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(CURRENT_DOCUMENT_LOADING, false);
        }
    },
    [GET_CURRENT_DOCUMENT]: async ({commit}, payload) => {
        commit(CURRENT_DOCUMENT_LOADING, true);
        try {
            const response = await $http.get(`v1/profile/documents/${payload.document_id}?type=${payload.type}&sub_type=${payload.sub_type}`);
            commit(SET_CURRENT_DOCUMENT, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CURRENT_DOCUMENT_LOADING, false);
        }
    },
    [GET_DOCUMENTS]: async ({commit}, payload) => {
        // commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/profile/documents?date_from=${payload.date_from}&date_to=${payload.date_to}`);
            commit(SET_DOCUMENTS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_USER_DATA]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/profile/me`);
            commit(SET_USER_DATA_PROFILE, response.data.data);
            const setUserFullName = {lastName: response.data.data.lastName, name: response.data.data.name}
            commit(SET_USER_FULL_NAME, setUserFullName);
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [UPDATE_USER_DATA]: async ({commit}, payload) => {
        try {
            await $http.put(`v1/profile/update`, payload);
        } catch (e) {
            throw e;
        }
    },
    [GET_LEGAL_USERS]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/profile/legal-user`);
            commit(SET_LEGAL_USERS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_OWNERSHIP_TYPES]: async ({commit}) => {
        try {
            const response = await $http.get(`v1/ownership-types`);
            commit(SET_OWNERSHIP_TYPES, response.data.data);
        } catch (e) {
            throw e;
        }
    },
    [UPDATE_LEGAL_USER]: async ({commit}, payload) => {
        commit(LOADING_BUTTON_LOADER, true);
        try {
            await $http.put(`v1/profile/legal-user/${payload.id}`, payload);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_BUTTON_LOADER, false);
        }
    },
    [CREATE_LEGAL_USER]: async ({commit}, payload) => {
        commit(LOADING_BUTTON_LOADER, true);
        try {
            const response = await $http.post(`v1/profile/legal-user`, payload);
            commit(SET_LEGAL_USERS, response.data.data);
            commit(SET_IS_CREATED_LEGAL, true);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_BUTTON_LOADER, false);
        }
    },
    [GET_CURRENT_ORDERS]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/order/profile/history/current`);
            commit(SET_CURRENT_ORDERS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_HISTORY_ORDERS]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/order/profile/history/filtered`);
            commit(SET_HISTORY_ORDERS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_DISCOUNTS]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            commit(PROFILE_LENDING_LOADING, true);
            const response = await $http.get(`v1/profile/discounts/active`);
            commit(SET_DISCOUNTS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_DISCOUNT_DESCRIPTION]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/profile/discounts/description`);
            commit(SET_DISCOUNT_DESCRIPTION, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_CATALOG]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/catalog/products`);
            commit(SET_CATALOG_GROUPS, response.data.data.groups)
            commit(SET_SHOWING_RETAIL_PRICE, response.data.meta.showRetailColumn)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_GREENHOUSE]: async ({commit}) => {
        commit(PROFILE_LENDING_LOADING, true);
        try {
            const response = await $http.get(`v1/catalog/products/specific/agroteplici`);
            commit(SET_CATALOG_GROUPS, response.data.data.groups)
            commit(SET_SHOWING_RETAIL_PRICE, response.data.meta.showRetailColumn)
        } catch (e) {
            throw e;
        } finally {
            commit(PROFILE_LENDING_LOADING, false);
        }
    },
    [GET_PRODUCT_GROUP]: async ({commit}, payload) => {
        try {
            const response = await $http.get(`v1/catalog/products/${payload}`);
            commit(SET_PRODUCT_GROUPS, response.data.data)
        } catch (e) {
            throw e;
        }
    },

};

const mutations = {
    [SET_USER_DATA_PROFILE](state, data) {
        const {name, lastName, middleName, email, phone, bonuses, id, debtsInfo} = data
        state.userInfo = {
            id,
            name,
            lastName,
            middleName,
            bonuses,
            debtsInfo
        }
        state.userContacts = {
            email,
            phone
        }
    },
    [SET_LEGAL_USERS](state, data) {
        state.legalUsers = data.map(({id, type, edrpo, tin, juridicalAddress, realAddress}) =>
            ({
                id,
                type: {id: type.id, title: type.title},
                code: tin,
                edrpo,
                legalCity: {title: juridicalAddress.split(',').length === 2 ? juridicalAddress.split(',')[0] : ''},
                legalAddress: juridicalAddress.split(',').length === 2 ? juridicalAddress.split(',')[1] : juridicalAddress,
                city: {title: realAddress.split(',').length === 2 ? realAddress.split(',')[0] : ''},
                address: realAddress.split(',').length === 2 ? realAddress.split(',')[1] : realAddress
            })
        )
    },
    [SET_OWNERSHIP_TYPES](state, data) {
        state.ownershipTypes = data
    },
    [PROFILE_LENDING_LOADING](state, data) {
        state.isLoadingProfile = data
    },
    [LOADING_BUTTON_LOADER](state, data) {
        state.isLoadingButtonLoader = data
    },
    [SET_CURRENT_ORDERS](state, data) {
        state.currentOrders = data
    },
    [SET_HISTORY_ORDERS](state, data) {
        state.historyOrders = data
    },
    [SET_DISCOUNTS](state, data) {
        state.discounts = data
    },
    [SET_DISCOUNT_DESCRIPTION](state, data) {
        state.discountDescription = data
    },
    [SET_CATALOG_GROUPS](state, data) {
        state.catalogGroups = data
    },
    [SET_PRODUCT_GROUPS](state, data) {
        data.products.data.forEach(item => item.count = 1)
        state.productGroups = [...state.productGroups, data]
    },
    [RESET_PRODUCT_GROUPS](state) {
        state.productGroups.forEach(el => el.products.data.forEach(item => item.count = 1))
    },
    [SET_SHOWING_RETAIL_PRICE](state, data) {
        state.isShowRetailPrice = data
    },
    [SET_USER_FULL_NAME](state, data) {
        state.userFullName = data
    },
    [SET_IS_CREATED_LEGAL](state, data) {
        state.creatingNewLegalSuccess = data
    },
    [SET_DOCUMENTS](state, data) {
        state.userDocuments = data
    },
    [SET_DOCUMENT_POPUP](state, data) {
        state.documentPopup = data
    },
    [SET_CURRENT_DOCUMENT](state, data) {
        state.currentDocument = data
    },
    [CURRENT_DOCUMENT_LOADING](state, status) {
        state.documentLoading = status
    },
    [SET_DOCUMENT_PDF](state, data) {
        state.documentPdf = data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
