import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
  name: "thanks-popup",
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {

  },
  watch: {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),

  }
}
