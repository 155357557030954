import {mapGetters, mapMutations} from "vuex";


//sections


export default {
  name: "characteristics",
  components: {},
  data() {
    return {

      collection: []
    }
  },
  created() {

  },
  mounted() {
    document.title = `${this.currentProduct.title +' - '+ this.$t('productCard.characteristics.title')}  | karbosnab`
  },
  computed: {
    ...mapGetters({
      currentProduct: 'catalog/currentProduct'
    })
  },

  methods: {
    ...mapMutations({})
  }
}
